import { atom } from "recoil";
import { IChatMessage, INotification } from "../interfaces";

export const connectionStatusAtom = atom<boolean>({
  key: "connectionStatus",
  default: false,
});

export const publishNotifyAtom = atom<INotification | null>({
  key: "publishNotify",
  default: null
});

export const receivedNotifyAtom = atom<INotification | null>({
  key: "receivedNofity",
  default: null
});

export const publishNewNotifyAtom = atom<string | undefined>({
  key: "publishNewNotify",
  default: undefined
});

export const receivedNewNotifyAtom = atom<boolean>({
  key: "receivedNewNofity",
  default: false
});

export const prevChatroomIdAtom = atom<string | null> ({
  key: "prevChatroomId",
  default: null
});

export const chatroomIdAtom = atom<string | null> ({
  key: "chatroomId",
  default: null
});

export const publishChatMessageAtom = atom<IChatMessage | null>({
  key: "publishChatMessage",
  default: null
});

export const receivedChatMessageAtom = atom<IChatMessage | null>({
  key: "receivedChatMessage",
  default: null
});

export const reloadChatroomAtom = atom<string | null>({
  key: "reloadChatroom",
  default: null
})

export const receivedReloadChatroomAtom = atom<string | null>({
  key: "receivedReloadChatroom",
  default: null
})

export const onlineUsersMemberNoAtom = atom<Set<string>>({
  key: "onlineUsersMemberNo",
  default: new Set()
})
